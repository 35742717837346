import { FieldValues, set, useForm } from "react-hook-form";
import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import AutocompleteFormControl from "../form-helpers/AutocompleteFormControl";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as api from "../../api/api-client";
import BasicFormControl from "../form-helpers/BasicFormControl";
import SelectFormControl from "../form-helpers/SelectFormControl";
import { get } from "http";
import CustomFormControl from "../form-helpers/CustomFromControl";

interface Props {
	onNoticeInput: (data: FieldValues) => void;
}

const CreateNoticeFormSpecial = (props: Props) => {
	const [vehicles, setVehicles] = useState<any>([]);
	const [vehiclesLoaded, setVehiclesLoaded] = useState<boolean>(false);
	const [addresses, setAddresses] = useState<any>([]);
	const [newAddress, setNewAddress] = useState<boolean>(false);
	



	const {
		handleSubmit,
		formState: { errors },
		control,
		setValue,
		getValues,
		reset,
		watch,
	} = useForm({
		defaultValues: {
			reg_no: "",
			pickup_address: "",
			drop_off_address: "",
			product_name: "",
			quantity: "",
			product_id: "",
			car_type: "utilaj",
			observation: "",
		},
	});


	useEffect(() => {
		api
			.get("/user/vehicle")
			.then((res) => {
				if (res) {
					setVehicles([res]);
				}
			})
			.catch((err) => {
				toast.error(err.message);
			})
			.finally(() => {
				setVehiclesLoaded(true);
			});
	}, []);

	useEffect(() => {
		if (vehicles && vehicles.length > 0) {
			reset({
				reg_no: vehicles[0]?.reg_no,
			});
		}
	}, [vehicles]);

	const onNoticeInput = (data: FieldValues) => {
		data.type = "notice";
		data.status = "unpaid";
		data.currency = "RON";
		data.unit_of_measure = "ORE";
		data.drop_off_address = data.pickup_address;
		data.product_id = 1;
		data.work_hour = vehicles[0]?.work_hour;
		data.stationary_hour = vehicles[0]?.stationary_hour;
		
		props.onNoticeInput(data);
	};
 
	const onAddressInput = (event: any) => {
		const { value } = event.target;

		if (value.length >= 3) {
			api
				.get(`/partners/address/autocomplete?searchKey=${value}`)
				.then((res) => {
					setAddresses(res);
					console.log(res);
				})
				.catch((err) => {
					toast.error(err);
				});
		}
	};

	const onVehicleInput = (event: any) => {
		const { value } = event.target;

		if (value.length >= 3) {
			api
				.get("/autofleet/options")
				.then((res) => {
					setVehicles(res);
				})
				.catch((err) => {
					toast.error(err.message);
				});
		}
	};

	const onVehicleChange = (event: any, value: any) => {
		setValue("reg_no", value?.reg_no);
	};

	const onPickupChange = (event: any, value: any) => {
		setValue("pickup_address", value);
		setNewAddress(true);
	};

	return (
		<>
			<form onSubmit={handleSubmit(onNoticeInput)}>
				<Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
					<Box sx={{ display: "flex" }}>
						{vehiclesLoaded && (
							<AutocompleteFormControl
								control={control}
								name="reg_no"
								displayText="Numar de inmatriculare "
								options={vehicles}
								onChangeHandler={onVehicleChange}
								onInputHandler={onVehicleInput}
								optionLabelFunc={(vehicles) => vehicles?.reg_no || ""}
								errors={errors}
								rules={{
									required: { value: true, message: "Campul este obligatoriu" },
								}}
								defaultValue={{
									reg_no: vehicles?.[0]?.reg_no || "",
								}}
							/>
						)}

					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: { xs: "column", md: "row" },
						}}>
						<AutocompleteFormControl
							control={control}
							name="pickup_address"
							displayText="Selectare santier"
							options={addresses}
							onChangeHandler={onPickupChange}
							onInputHandler={onAddressInput}
							// onBlurHandler={onPickupBlur}
							optionLabelFunc={(addresses) => addresses?.nickname || ""}
							errors={errors}
							rules={{
								required: { value: true, message: "Campul este obligatoriu" },
							}}
						/>
				
						<SelectFormControl
							sx={{ width: "100%", marginTop: "0.5rem" }}
							control={control}
							name="car_type"
							selectOptions={[
								{ label: "Utilaj", value: "utilaj" },
								{ label: "Remorca", value: "remorca" },
								{ label: "Cap remorca", value: "cap_remorca" },
							]}
							defaultValue={{
								label: "Utilaj",
								value: "utilaj",
							}}
							displayText="Tip vehicul"
						/>
					</Box>

					<Box
						sx={{
							display: "flex",
							flexDirection: { xs: "column", md: "row" },
						}}>
						<BasicFormControl
							control={control}
							name="quantity_stationary"
							displayText="Ore Stationare"
						/>
						<BasicFormControl
							control={control}
							name="quantity"
							displayText="Ore Lucrate"
							
						/>
						
						<BasicFormControl
							control={control}
							name="observation"
							displayText="Observatii"
							errors={errors}
						/>
						
					</Box>
					<Button type="submit" variant="contained" sx={{ mt: "2rem" }}>
						Submit
					</Button>
				</Box>
			</form>
		</>
	);
};

export default CreateNoticeFormSpecial;
