import { Box, Typography } from "@mui/material";
import ReportsCard from "../reports-card/ReportsCard";
import {
	Description,
	DriveEta,
	EmojiTransportation,
	MonetizationOn,
	ReceiptLong,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";

interface Props {
	content: any;
}

interface ProductTransportDescription {
	unit_of_measure: string;
	quantity: number;
}

const NoticeReports = (props: Props) => {
	const [totalNotices, setTotalNotices] = useState<number>(0);
	const [totalPrice, setTotalPrice] = useState<number>(0);
	const [productTransportDescriptions, setProductTransportDescriptions] =
		useState<ProductTransportDescription[]>([]);

		const [productTransportDescriptionsUtilaje, setProductTransportDescriptionsUtilaje] =
		useState<ProductTransportDescription[]>([]);	
	useEffect(() => {
		setTotalNotices(props?.content?.length);
	}, [props.content]);

	useEffect(() => {
		let mc: ProductTransportDescription = {
			unit_of_measure: "MC",
			quantity: 0,
		};
		let to: ProductTransportDescription = {
			unit_of_measure: "TO",
			quantity: 0,
		};
		let curse: ProductTransportDescription = {
			unit_of_measure: "CURSE",
			quantity: 0,
		};
		let ore: ProductTransportDescription = {
			unit_of_measure: "Ore de lucru",
			quantity: 0,
		};
		let ore_stationary: ProductTransportDescription = {
			unit_of_measure: "Ore de stationare",
			quantity: 0,
		};
		let total_price = 0;

		props?.content?.forEach((notice: any) => {
			const invoiceProduct = notice.InvoiceProducts?.[0];

			if (invoiceProduct?.unit_of_measure === "MC")
				mc.quantity += Number(invoiceProduct.quantity);
			if (invoiceProduct?.unit_of_measure === "TO")
				to.quantity += Number(invoiceProduct.quantity);
			if (invoiceProduct?.unit_of_measure === "CURSE")
				curse.quantity += Number(invoiceProduct.quantity);
			if (invoiceProduct?.unit_of_measure === "ORE"){
				ore.quantity += Number(invoiceProduct.quantity);
				total_price += Number(notice.total_price);
			}
			if (invoiceProduct?.unit_of_measure === "ORE"){
				ore_stationary.quantity += Number(invoiceProduct.quantity_stationary);
			}
				
		});

		mc.quantity = parseFloat(mc.quantity?.toFixed(2));
		to.quantity = parseFloat(to.quantity?.toFixed(2));
		curse.quantity = parseFloat(curse.quantity?.toFixed(2));
		ore.quantity = parseFloat(ore.quantity?.toFixed(2));
		ore_stationary.quantity = parseFloat(ore_stationary.quantity?.toFixed(2));

		setProductTransportDescriptions([to, mc, curse]);
		setProductTransportDescriptionsUtilaje([ore, ore_stationary]);
		setTotalPrice(total_price);
	}, [props.content]);

	return (
		<Box
			sx={{
				display: "flex",
				flexWrap: "wrap",
			}}>
			<ReportsCard
				titleText="Transporturi"
				icon={<EmojiTransportation />}
				content={
					<>
						{productTransportDescriptions.map(
							(productTransportDescription: ProductTransportDescription) => (
								<Box sx={{ display: "flex" }}>
									<Typography
										color="green"
										fontWeight="bold"
										sx={{ marginRight: "0.5rem" }}>
										{productTransportDescription.quantity}
									</Typography>
									<Typography
										color="white"
										fontWeight="bold"
										sx={{ textShadow: "1px 1px 2px black" }}>
										{productTransportDescription.unit_of_measure}
									</Typography>
								</Box>
							)
						)}
					</>
				}
			/>
			<ReportsCard
				titleText="Ore"
				icon={<EmojiTransportation />}
				content={
					<>
						{productTransportDescriptionsUtilaje.map(
							(productTransportDescription: ProductTransportDescription) => (
								<Box sx={{ display: "flex" }}>
									<Typography
										color="green"
										fontWeight="bold"
										sx={{ marginRight: "0.5rem" }}>
										{productTransportDescription.quantity}
									</Typography>
									<Typography
										color="white"
										fontWeight="bold"
										sx={{ textShadow: "1px 1px 2px black" }}>
										{productTransportDescription.unit_of_measure}
									</Typography>
								</Box>
							)
						)}
						<Box sx={{ display: "flex" }}>
									<Typography
										color="white"
										fontWeight="bold"
										sx={{ textShadow: "1px 1px 2px black" }}>
										{`Total : ` }
									</Typography>
									<Typography
										color="green"
										fontWeight="bold"
										sx={{ marginLeft: "0.5rem" }}>
										{`${totalPrice} lei`}
									</Typography>
								</Box>
							
					</>
					
				}
			/>
			<ReportsCard
				titleText="Numar avize"
				icon={<ReceiptLong />}
				content={
					<Box sx={{ display: "flex" }}>
						<Typography
							color="green"
							fontWeight="bold"
							sx={{
								marginRight: "0.5rem",
							}}>
							{totalNotices}
						</Typography>
						<Typography
							color="white"
							fontWeight="bold"
							sx={{
								marginRight: "0.5rem",
								textShadow: "1px 1px 2px black",
								fontSize: "1rem",
							}}>
							{totalNotices === 1 ? "aviz" : "avize"}
						</Typography>
					</Box>
				}
			/>
		</Box>
	);
};

export default NoticeReports;
