import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import Table from "../../components/table/Table";
import { InvoiceTableHeaders } from "./InvoiceTableConstants";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import * as api from "../../api/api-client";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { Close } from "@mui/icons-material";
import PdfTemplate from "../../components/pdf-templates/PdfTemplate";
import DeleteConfirmationDialog from "../../components/DeleteConfirmationDialog";
import { FieldValues, set } from "react-hook-form";
import CreateNoticeForm from "../../components/forms/CreateNoticeForm";
import InvoicePdfTemplate from "../../components/pdf-templates/InvoicePdfTemplate";
import { FilterSettings } from "../../types/FilterTypes";
import { KeyValuePair } from "../../types/CommonTypes";
import Filters from "../../components/filters/Filters";
import { NoticesTableHeader } from "./NoticesTableConstants";
import NoticeReports from "../../components/notice/NoticeReports";
import XlsxTemplate from "../../components/xlsx-templates/XlsxTemplate";
import * as XLSX from "xlsx";
import InvoiceVariantPdfTemplate from "../../components/pdf-templates/InvoiceVariantPdfTemplate";
import InvoiceAvizePdfTemplate from "../../components/pdf-templates/InvoiceAzivePdfTemplate";
import jwtDecode from "jwt-decode";
import useAuth from "../../context/AuthContext";
import CreateNoticeFormSpecial from "../../components/forms/CreateNoticeFormSpecial";


const NoticesPage = () => {
  const [invoices, setInvoices] = useState<any>([]);
  const [pdfInvoiceId, setPdfInvoiceId] = useState<number>(0);
  const [exportToPdfDialogOpen, setExportToPdfDialogOpen] = useState<boolean>(false);
  const [rowDeleteRequested, setRowDeleteRequested] = useState<any>(undefined);
  const [createInvoiceDialogOpen, setCreateInvoiceDialogOpen] = useState<boolean>(false);
  const [selectedInvoice, setSelectedInvoice] = useState<any>({});
  const [filters, setFilters] = useState<KeyValuePair>({} as KeyValuePair);
  const [queryParams, setQueryParams] = useState<string>();
  const [reset, setReset] = useState<number>(0);
  const [logo, setLogo] = useState<any>();
  const [user, setUser] = useState<any>();
  const [isCarUtilaj, setIsCarUtilaj] = useState<boolean>(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const navigate = useNavigate();
  const userContext = useAuth();
  const token: any = jwtDecode(userContext.token!);

  const filterable: FilterSettings[] = [
    {
      key: 'series',
      display: 'Serie',
      type: 'text',
    },
    {
      key: 'number',
      display: 'Numar',
      type: 'number'
    },
    {
      key: 'created_from',
      display: 'Data emitere (de la)',
      type: 'date'
    },
    {
      key: 'created_to',
      display: 'Data emitere (pana la)',
      type: 'date'
    },
    {
      key: 'unit_of_measure',
      display: 'U.M.',
      type: 'text'
    },
    {
      key: 'product_name',
      display: 'Nume produs',
      type: 'text'
    },
    {
      key: 'pick_up_location',
      display: 'Locatie incarcare',
      type: 'text'
    },
    {
      key: 'drop_off_location',
      display: 'Locatie descarcare',
      type: 'text'
    },
    {
      key: "driver_name",
      display: "Sofer",
      type: 'text'
    },
    {
      key: "car_reg_number",
      display: "Masina",
      type: 'text'
    },
  ]

  const actions: any[] = [
    {
      name: 'handleExportToPdf',
      displayName: 'Export to PDF',
      action: handleExportToPdf
    },
    {
      name: 'deleteInvoiceRow',
      displayName: 'Delete row',
      action: handleDeleteRowRequest
    },
    {
      name: 'handleExportToExcel',
      displayName: 'Export to Excel',
      action: handleExportToExcel
    }
  ]

  const location = useLocation();

  useEffect(() => {
    if (queryParams !== location.search) {
      setQueryParams(location.search);
    }
    if (queryParams?.includes("showModal=true")) {
      setQueryParams("");
      setCreateInvoiceDialogOpen(true);
    }
  }, [location]);

  useEffect(() => {
    api.get(`/invoices${queryParams || '?'}&type=notice`).then((res: any) => {
      res.forEach((resItem: any) => {
        resItem.buyer = resItem?.buyer?.name;
        resItem.drop_off_address = resItem?.drop_off_address?.nickname || undefined;
        resItem.pickup_address = resItem?.pickup_address?.nickname || undefined;
        resItem.product_name = resItem?.InvoiceProducts?.[0]?.product?.product_name;
        resItem.unit_of_measure = resItem?.InvoiceProducts?.[0]?.unit_of_measure;
        resItem.quantity = resItem?.InvoiceProducts?.[0]?.quantity;
        resItem.quantity_stationary = resItem?.InvoiceProducts?.[0]?.quantity_stationary;
        resItem.driver_name = resItem?.driver_name || undefined;
        // resItem.uit = <Button>Trimite e-Transport</Button>

        // api.get(`/users/${resItem.user_id}`).then((res: any) => {
        //   resItem.driver_name = res?.first_name + ' ' + res?.last_name;
        // }).then(() => {
        //   setInvoices(res);
        // })

        setInvoices(res);

      })
    }).catch((err) => {
      toast.error(err);
    });

    api.get(`/autofleet/${token._id}`).then((res) => {

      if(res.car_type === 'utilaj'){
        setIsCarUtilaj(true); 
      }
      
    }).catch((err) => {
      toast.error(err);
    });
  }, [queryParams, reset]);
 
  useEffect(() => {
    if (filters) {
      navigate(`?${new URLSearchParams(filters).toString()}`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  function handleExportToPdf(row: any) {
    Promise.all([
      api.get(`/invoices/${row.invoice_id}`),
      api.get('/users/current')])
      .then((res: any) => {
        setSelectedInvoice(res[0]);
        setUser(res[1]);
        setExportToPdfDialogOpen(true);
      }).catch((err) => {
        toast.error(err.message);
      });
  }

  function handleDeleteRowRequest(row: any) {
    setRowDeleteRequested(row);
  }

  function handleGenerateETransport(id: any) {
    api.put(`/orders/send/${id}`, {
      codScopOperatiune: "704"
    }).then((res) => {
      api.get(`/invoices${queryParams || '?'}&type=notice`).then((res: any) => {
        res.forEach((resItem: any) => {
          resItem.buyer = resItem?.buyer?.name;
          resItem.drop_off_address = resItem?.drop_off_address?.nickname || undefined;
          resItem.pickup_address = resItem?.pickup_address?.nickname || undefined;
          resItem.product_name = resItem?.InvoiceProducts?.[0]?.product?.product_name;
          resItem.unit_of_measure = resItem?.InvoiceProducts?.[0]?.unit_of_measure;
          resItem.quantity = resItem?.InvoiceProducts?.[0]?.quantity;
          resItem.driver_name = resItem?.driver_name || undefined;
          setInvoices(res);
        })
      }).catch((err) => {
        toast.error(err);
      });
      toast.success("Transmis cu succes");
    }).catch((err) => {
      toast.error(err);
    });
  }

  const handleDeleteRow = () => {
    api.remove(`/invoices/${rowDeleteRequested.invoice_id}`).then((res) => {
      toast.success(res.message);
      setReset(reset + 1);
    }).catch((err) => {
      toast.error(err.message);
    }).finally(() => {
      setRowDeleteRequested(undefined);
    })
  }

  const rowDeleteCanceled = (row: any) => {
    setRowDeleteRequested(null);
  }

  const openCreateInvoiceDialog = () => {
    setCreateInvoiceDialogOpen(true);
  }

  function handleExportToExcel(row: any) {

    var obj: any = {};
    for (let field of NoticesTableHeader) {
      obj[field.label] = typeof (field.id) == 'string' ? row[field.id] : [row[field.id[0]], row[field.id[1]]].join(" ") || "-";
    }
    var aviz = [obj];

    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(aviz);
    ws['!cols'] = NoticesTableHeader.map((col: any) => ({ wch: 15 }));

    XLSX.utils.book_append_sheet(wb, ws, "Sheet");
    XLSX.writeFile(wb, `Aviz-${row?.series}-${row?.number}.xlsx`);
  }

  const handleCreateInvoice = (data: FieldValues) => {
    api.post('/invoices/notice', data).then((res) => {
      toast.success(res.message);
      setCreateInvoiceDialogOpen(false);
      setReset(reset + 1);
    }).catch((err) => {
      toast.error(err.message || 'Something went wrong!');
    })
  }

  const formatCell = (row: any, value: string, fieldName: string | string[]) => {
    return <Link to={`/notices/${row.invoice_id}`}>{value}</Link>
  }

  const handlePdfDialogClose = (value: boolean) => {
    setExportToPdfDialogOpen(value);
    setSelectedInvoice({});
  }

  const onFilterSubmit = (filters: KeyValuePair) => {
    setFilters(filters);
  }

  const handleSendETransport = (row: any) => {
    // const codTarifar = row.InvoiceProducts.map((invoice: any) => invoice.product.nc8_code_id)
    // const codTarifar = "7228";
    handleGenerateETransport(row.invoice_id)
  }

  return (
    <Container sx={{ height: '100%', margin: 0, marginTop: '3rem', maxWidth: '100vw !important' }}>
      <NoticeReports content={invoices} />
      <Filters filters={filters} filtersSettings={filterable} onFiltersSubmit={onFilterSubmit} />
      <Box sx={{ display: 'flex', flexDirection: 'row-reverse', marginTop: '0.5rem' }}>
        <XlsxTemplate exportName="Avize" excelData={invoices} excelHeaders={NoticesTableHeader} />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Table headers={NoticesTableHeader}
          rows={invoices}
          actions={actions}
          handleExportToPdf={handleExportToPdf}
          getFormattedCell={formatCell}
          handleAddActionClick={openCreateInvoiceDialog}
          sendETransport={handleSendETransport}
        ></Table>
      </Box>

      <Dialog
        fullScreen={fullScreen}
        open={createInvoiceDialogOpen}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="responsive-dialog-title"
          sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant='h5'>Adauga un aviz</Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => setCreateInvoiceDialogOpen(false)}
            aria-label="close"
            sx={{ justifySelf: 'end' }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ height: '100%' }}>
          {isCarUtilaj ? <CreateNoticeFormSpecial onNoticeInput={handleCreateInvoice} /> : <CreateNoticeForm onNoticeInput={handleCreateInvoice} />}
        </DialogContent>
      </Dialog>

    
      <Dialog
        fullScreen={fullScreen}
        open={exportToPdfDialogOpen}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="responsive-dialog-title"
          sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => setExportToPdfDialogOpen(false)}
            aria-label="close"
            sx={{ justifySelf: 'end' }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ height: '100%' }}>
          <PdfTemplate invoiceData={selectedInvoice}
            exportName={`Aviz-${selectedInvoice?.series}-${selectedInvoice?.number}`}
            handleDialogOpenState={(value) => handlePdfDialogClose(value)}>
            <InvoiceAvizePdfTemplate invoiceData={selectedInvoice} user={user} />
          </PdfTemplate>
        </DialogContent>
      </Dialog>

      <DeleteConfirmationDialog rowDeleteRequested={rowDeleteRequested}
        deleteConfirmationText={rowDeleteRequested?.number + rowDeleteRequested?.series}
        handleDeleteRow={handleDeleteRow} rowDeleteCanceled={rowDeleteCanceled} />

    </Container>
  )
}

export default NoticesPage;
