export const AutoFleetTableConstants = [
  {
    id: 'reg_no',
    label: 'Numar de inmatriculare',
    width: 100,
    type: 'href'
  },
  {
    id: 'model',
    label: 'Denumire vehicul',
    width: 100,
  },
  {
    id: 'vignette_ro',
    label: 'Rovinieta',
    width: 100,
    type: 'date',
    coloredCell: true
  },
  {
    id: 'vignette_hu',
    label: 'Vinieta HU',
    width: 100,
    type: 'date',
    coloredCell: true
  },
  {
    id: 'vignette_slo',
    label: 'Vinieta SLO',
    width: 100,
    type: 'date',
    coloredCell: true
  },
  {
    id: 'itp',
    label: 'ITP',
    width: 100,
    type: 'date',
    coloredCell: true
  },
  {
    id: 'cmr_insurance',
    label: "Asigurare CRM",
    width: 100,
    type: 'date',
    coloredCell: true
  },
  {
    id: 'carbon_copy',
    label: "Copie conforma",
    width: 100,
    type: 'date',
    coloredCell: true
  },
  {
    id: "casco",
    label: "Casco",
    width: 100,
    type: 'date',
    coloredCell: true
  },
  {
    id: "rca",
    label: "RCA",
    width: 100,
    type: 'date',
    coloredCell: true
  },
  {
    id: "max_weight_in_tons",
    label: "Tonaj maxim",
    width: 100,
    type: 'number',
    coloredCell: true
  },
  {
    id: "net_weight_in_tons",
    label: "Greutate neta",
    width: 100,
    type: 'number',
    coloredCell: true
  },
  {
    id: "stationary_hour",
    label: "Pret ora stationare",
    width: 100,
    type: 'number',
  },
  {
    id: "work_hour",
    label: "Pret ora lucru",
    width: 100,
    type: 'number',
  },
  {
    id: "car_type",
    label: "Tip vehicul",
    width: 200,
    type: 'text',
  }
]
