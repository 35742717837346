import {FieldValues, useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import BasicFormControl from "../form-helpers/BasicFormControl";
import AutocompleteFormControl from "../form-helpers/AutocompleteFormControl";
import * as api from "../../api/api-client";
import {toast} from "react-toastify";
import {Box, Button} from "@mui/material";
import DatePickerFormControl from "../form-helpers/DatePickerFormControl";
import SelectFormControl from "../form-helpers/SelectFormControl";

interface Props {
  onAutoFleetSubmit: (data: FieldValues) => void;
}
const carTypes = [{
    label: "Utilaj",
    value: "utilaj"
  },
  {
    label: "Masina",
    value: "masina"
  }];
const CreateAutoFleetForm = (props: Props) => {
  const [partners, setPartners] = useState<any>([]);

  const {
    handleSubmit,
    formState: {errors},
    control,
    setValue,
    reset,
    watch
  } = useForm();

  const watchRegNo = watch('reg_no');
  const watchVin = watch('vin');

  useEffect(() => {
    if (watchRegNo) {
      setValue('reg_no', watchRegNo.replace(/ /g, '').toUpperCase());
    }
  }, [watchRegNo]);

  useEffect(() => {
    if (watchVin) {
      setValue('vin', watchVin.replace(/ /g, '').toUpperCase());
    }
  }, [watchVin])

  const onPartnerAutocompleteInput = (event: any) => {
    const {value} = event.target;

    if (value.length >= 3) {
      api.get(`/partners/user/autocomplete?searchKey=${value}`).then((res) => {
        setPartners(res);
      }).catch((err) => {
          toast(err, {type: 'error'});
          console.error(err);
        }
      )
    }
  }

  const onPartnerAutocompleteChange = (event: any, value: any) => {
    setValue('partner', value.partner_id);
    setValue('partner_id', value.partner_id);
  }

  const handleAutoFleetSubmit = (data: FieldValues) => {
    props.onAutoFleetSubmit(data);
  }

  return (
    <form onSubmit={handleSubmit(handleAutoFleetSubmit)}>
      <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
        <AutocompleteFormControl control={control}
                                 name='partner'
                                 displayText='Firma'
                                 options={partners}
                                 optionLabelFunc={(partners) => partners?.partner_name || ""}
                                 onInputHandler={onPartnerAutocompleteInput}
                                 onChangeHandler={onPartnerAutocompleteChange}
                                 variant='outlined'
                                 rules={{
                                   required: {value: true, message: 'Firma este obligatorie.'}
                                 }}
        />
      </Box>

      <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
        <BasicFormControl control={control}
                          name='reg_no'
                          displayText='Numar de inmatriculare'
                          errors={errors}
                          variant='outlined'
                          rules={{
                            required: {value: true, message: 'Numarul de inmatriculare este obligatoriu.'}
                          }}/>
        <BasicFormControl control={control}
                          name='vin'
                          displayText='Serie sasiu'
                          errors={errors}
                          variant='outlined'
                          rules={{
                            required: {value: true, message: 'Seria de sasiu este obligatorie.'}
                          }}/>
        <BasicFormControl control={control}
                          name='model'
                          displayText='Model'
                          errors={errors}
                          variant='outlined'
                          rules={{
                            required: {value: true, message: 'Modelul este obligatoriu.'}
                          }}/>
      </Box>

      <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
        <DatePickerFormControl control={control} name='vignette_ro' displayText='Rovinieta' variant='outlined'/>
        <DatePickerFormControl control={control} name='vignette_hu' displayText='Vinieta HU' variant='outlined'/>
        <DatePickerFormControl control={control} name='vignette_slo' displayText='Vinieta SLO' variant='outlined'/>
      </Box>

      <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
        <DatePickerFormControl control={control} name='itp' displayText='ITP' variant='outlined'/>
        <DatePickerFormControl control={control} name='cmr_insurance' displayText='Asigurare CMR' variant='outlined'/>
        <DatePickerFormControl control={control} name='rca' displayText='RCA' variant='outlined'/>
      </Box>

      <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
        <DatePickerFormControl control={control} name='carbon_copy' displayText='Copie conforma' variant='outlined'/>
        <DatePickerFormControl control={control} name='casco' displayText='Casco' variant='outlined'/>
      </Box>
      <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
          <BasicFormControl control={control}
                            name='max_weight_in_tons'
                            displayText='Tonaj maxim'
                            errors={errors}
                            variant='outlined'
                            type='number'
                            rules={{
                                required: {value: true, message: 'Tonajul maxim este obligatoriu.'}
                            }}/>
          <BasicFormControl control={control}
                            name='net_weight_in_tons'
                            displayText='Greutate neta'
                            errors={errors}
                            variant='outlined'
                            type='number'
                            rules={{
                                required: {value: true, message: 'Greutatea neta este obligatorie.'}
                            }}/>
      </Box>
      <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
          <BasicFormControl control={control}
                                name='stationary_hour'
                                displayText='Pret ora stationare'
                                errors={errors}
                                variant='outlined'
                                type='number'
                              />
          <BasicFormControl control={control}
                                name='work_hour'
                                displayText='Pret ora lucru'
                                errors={errors}
                                variant='outlined'
                                type='number'
                              />                   
      </Box>
      <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
      <SelectFormControl control={control} name='car_type' displayText='Tip vehicul' variant='outlined'
                                           errors={errors}
                                           selectOptions={carTypes}
                                           rules={{
                                               required: {value: true, message: 'Tipul este obligatoriu'}
                                           }}/>
      </Box>

      <Button type='submit' variant='contained' sx={{width: '100%', my: 2}}>Submit</Button>

    </form>
  )
}

export default CreateAutoFleetForm;
