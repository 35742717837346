import { FieldValues, useForm } from "react-hook-form";
import { Box, Button } from "@mui/material";
import BasicFormControl from "../form-helpers/BasicFormControl";
import { useEffect } from "react";
import * as api from "../../api/api-client";
import SelectFormControl from "../form-helpers/SelectFormControl";

interface Props {
	product: any;
	invoiceType: "received" | "notice" | "issued";
	editInvoiceProductForm: (data: FieldValues) => void;
}

const EditInvoiceProductForm = (props: Props) => {
	const {
		handleSubmit,
		formState: { errors },
		control,
		clearErrors,
		setError,
		reset,
		getValues,
		setValue,
		watch,
	} = useForm({
		defaultValues: {
			quantity: 0,
			product_name: "",
			type: "",
			purchase_price: "",
			unit_of_measure: "",
		},
	});

	const watchQuantity = watch("quantity");

	useEffect(() => {
		reset(props.product);
	}, []);

	useEffect(() => {
		if (watchQuantity) {
			if (!props.invoiceType || props.invoiceType === "received") {
				return;
			}

			if (getValues("type") === "service") return;

			if (props.invoiceType === "notice") return;

			setTimeout(() => {
				api
					.post("/products/quantity/check", {
						product_name: getValues("product_name"),
						quantity: getValues("quantity"),
					})
					.then((res) => {
						clearErrors("quantity");
					})
					.catch((err: any) => {
						console.error(err);
						setError("quantity", { message: "Insufficient quantity" });
					});
			}, 1000);
		}
	}, [watchQuantity]);

	const onProductInput = (data: FieldValues) => {
		props.editInvoiceProductForm(data);
	};

	return (
		<form onSubmit={handleSubmit(onProductInput)}>
			<Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}>
				<BasicFormControl
					control={control}
					name="quantity"
					displayText="Cantitate"
					variant="outlined"
					rules={{
						required: { value: true, message: "Campul este obligatoriu" },
						pattern: {
							value: /[0-9]/,
							message: "Campul trebuie sa fie un numar",
						},
					}}
					errors={errors}
				/>

				<BasicFormControl
					control={control}
					name="purchase_price"
					displayText="Pret"
					variant="outlined"
					rules={{
						required: { value: true, message: "Campul este obligatoriu" },
						pattern: {
							value: /[0-9]/,
							message: "Campul trebuie sa fie un numar",
						},
					}}
					errors={errors}
				/>

				<SelectFormControl
					sx={{ width: "100%", mt: "1rem" }}
					displayText="U.M."
					control={control}
					name="unit_of_measure"
					selectOptions={[
						{ label: "TO", value: "TO" },
						{ label: "MC", value: "MC" },
						{ label: "CURSE", value: "CURSE" },
					]}
					rules={{
						required: { value: true, message: "Campul este obligatoriu" },
					}}
					errors={errors}
				/>
			</Box>

			<Button
				type="submit"
				variant="contained"
				sx={{ width: "100%", my: "2rem" }}>
				Submit
			</Button>
		</form>
	);
};

export default EditInvoiceProductForm;
