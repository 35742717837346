function getDaysInMonth(month: number, year: number) {
	return new Date(year, month, 0).getDate();
}

// Function to generate headers for each day of the month
function generateDayHeaders(totalDays: number) {
	return Array.from({ length: totalDays }, (_, i) => ({
		id: `day_${i + 1}`,
		label: ` ${i + 1}`,
		width: -2,
	}));
}

const today = new Date();
const totalDays = getDaysInMonth(today.getMonth() + 1, today.getFullYear());
const dayHeaders = generateDayHeaders(totalDays);

const TimesheetTableHeader = [
	{
		id: 'index',
		label: 'Nr. crt',
		width: -1,
		composite: true
	},
	{
		id: ['last_name', 'first_name'],
		label: 'NUME ANGAJAT',
		width: 1,
		composite: true
	},
	{	
		id: "profession",
		label: "PROFESIE",
		width: 100,
	},
	{
		id: "santier",
		label: "SANTIER",
		width: 1
	}
];

TimesheetTableHeader.push(...dayHeaders);

TimesheetTableHeader.push(
	{
		id: "total_hours",
		label: "Total hours",
		width: 100,
	},
	{
		id: "suplimentary_hours",
		label: "Supl. hours",
		width: 100,
	},
	{
		id: "days_count",
		label: "BO",
		width: -1,
	},
	{
		id: "holidays_count",
		label: "CO",
		width: -1,
	}
);

export default TimesheetTableHeader;