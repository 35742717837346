import * as React from 'react';
import { useEffect, useState } from 'react';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Button, Tooltip } from '@mui/material';

import { RouteDefinition, routes } from '../router/routes';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { Logout } from "@mui/icons-material";
import useAuth from "../context/AuthContext";
import ABVLogo from "../assets/Logo ABVSoft.png";


const drawerWidth = 240;

export default function ResponsiveDrawer(props: any): JSX.Element {

  const navigate = useNavigate();
  const location = useLocation();

  const [showBackBtn, setShowBackBtn] = useState<boolean>(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [expanded, setExpanded] = useState<string>('');
  const [user, setUser] = useState<any>();

  const userContext = useAuth();

  const token = userContext.token;

  useEffect(() => {
    setUser(jwtDecode(token!));
    
    async function getRate() {
      const appRate = localStorage.getItem('eurRate');

      const currentRate = appRate ? JSON.parse(appRate) : undefined;

      if (currentRate?.date === getIsoDateNow()) return;

      if (!currentRate) {
        console.log('calculating rate');
        const rateInfo = await (await fetch(`https://api.openapi.ro/api/exchange/EUR?date=${getIsoDateNow()}`, {
          headers: {
            "x-api-key": "VzAEBCXbsz_qzkw1xny3Ny9WjvQCzxbXzJrjvA3qf75hDRsWfg"
          }
        }))?.json();

        const eurRate = {
          date: rateInfo.date,
          rate: rateInfo.rate
        }

        localStorage.setItem('eurRate', JSON.stringify(eurRate));
      }
    }

    getRate();
  }, []);

  useEffect(() => {
    location.pathname === "/" ? setShowBackBtn(false) : setShowBackBtn(true);
  }, [location]);


  function getIsoDateNow() {
    const currentDate = new Date(Date.now());
    const year = currentDate.getFullYear();
    let month = (currentDate.getMonth() + 1).toString();
    let dt = (currentDate.getDate()).toString();

    if (Number(dt) < 10) {
      dt = '0' + dt;
    }
    if (Number(month) < 10) {
      month = '0' + month;
    }

    return `${year}-${month}-${dt}`;
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const goToSettings = () => {
    navigate('/user/settings');
  }

  const logout = () => {
    userContext.logout();
  }

  const goBack = () => {
    if (location.pathname.includes("notice")) {
      navigate("../..", { relative: "path" });
    } else {
      navigate(-2);
    }
  };

  const drawer = (
    <>
      <Toolbar>
      <img src={ABVLogo} alt="ABV Logo" style={{ maxHeight: '65px' }} />
      
        <Divider orientation='vertical' flexItem />
       
        <Box sx={{ display: 'flex', flexDirection: 'column', pl: 1 }}>
          <Typography variant='body2'>ERP Solution</Typography>
        </Box>

      </Toolbar>

      <Divider />
      <Toolbar sx={{ mt: 2 }}>

        <Avatar sx={{ cursor: 'pointer' }} onClick={goToSettings}></Avatar>
        <Box sx={{ display: 'flex', flexDirection: 'column', pl: 1 }}>
          <Typography variant='body2'>{user?.name || ''}</Typography>
        </Box>

      </Toolbar>
      <List>
        {routes.filter(route => !route.roles || route.roles.includes(user?.role)).map((route: RouteDefinition, index: number) => (
          <React.Fragment key={`${route.key}-${route.title}-${index}`}>
            {route.nested ?
              <Accordion key={route.key} expanded={expanded === route.title} onChange={() => setExpanded(route.title)}>
                <AccordionSummary>
                  <ListItemButton>
                    <ListItemIcon>
                      <route.icon />
                    </ListItemIcon>
                    <ListItemText primary={route.title} />
                  </ListItemButton>
                </AccordionSummary>
                <AccordionDetails>
                  {/*{<Link to={route.path}>*/}
                  {/*    <ListItemButton>*/}
                  {/*        <ListItemText primary={`List ${route.title}`}/>*/}
                  {/*    </ListItemButton>*/}
                  {/*</Link>}*/}
                  {route.nested.filter(nestedRoute => !nestedRoute.roles || nestedRoute.roles.includes(user?.role))
                    .map((nestedRoute) => {
                      return (
                        <Link to={nestedRoute.path} key={nestedRoute.path}>
                          <ListItemButton>
                            <ListItemText primary={`${nestedRoute.title}`} />
                          </ListItemButton>
                        </Link>
                      )
                    })}
                </AccordionDetails>
              </Accordion>
              :
              <Box sx={{ pl: 2 }} key={`nested-${index}`}>
                <Link to={route.path}>
                  <ListItemButton>
                    <ListItemIcon>
                      <route.icon />
                    </ListItemIcon>
                    <ListItemText primary={route.title} />
                  </ListItemButton>
                </Link>
              </Box>
            }

          </React.Fragment>
        ))}
      </List>
      <Box sx={{ display: 'flex', height: '100%', alignItems: 'flex-end', justifyContent: 'center', mb: 3 }}>
        <Typography variant='caption'>© ABVSoft Ro, Inc. All rights reserved </Typography>
      </Box>
    </>
  );

  return (

    <Box display='flex'>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >

            <MenuIcon />
          </IconButton>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: "20px", alignItems: "center" }}>
              {showBackBtn && (
                <Button
                  variant="contained"
                  color="secondary"
                  size="medium"
                  onClick={() => goBack()}
                  startIcon={<ChevronLeftIcon />}>
                  Back
                </Button>
              )}
              <Typography variant="h6" component="div">
                Dashboard
              </Typography>
            </Box>
            <Box>
              <Tooltip title='Logout'>
                <IconButton onClick={logout} sx={{ color: 'white' }}>
                  <Logout />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>

        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 }, height: '100%' }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            height: '100%',
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: { xs: '100vh', md: 'calc(100vh - 60px)' },
          p: 3,
          width: `calc(100% - ${drawerWidth}px)`
        }}
      >
        {props.children}
      </Box>

    </Box>

  );
}
