import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { useEffect, useState } from 'react';
import * as api from "../../api/api-client";
import { toast } from "react-toastify";

const SettingsAnafPage = () => {
	const [status, setStatus] = useState(false);
	const [currentUser, setCurrentuser] = useState<any>();

	const generateToken = async (id: string) => {
		try {
			const response = await api.get(`/anaf/authorize?id=${id}`);
			const data = await response.json();
			console.log("generare token data: ", data);
			toast.success("Token generat cu succes");
			setStatus(true);
		} catch (error) {
			console.log("generare token error: ", error);
			toast.error("Cors policy on Anaf server");
		}
	}

	useEffect(() => {
		api.get('/users/current').then((res) => {
			setCurrentuser(res);
			if(res.token_anaf) setStatus(true);
		}).catch((err) => {
		  toast.error(err);
		});
	  }, []);

	return (
		<Container sx={{ height: '100%', margin: 0, marginTop: '3rem', maxWidth: '100vw !important' }}>
			<Typography variant='h4'>Setari Anaf</Typography>
			<Box sx={{ my: 4, width: '50%' }}>
				<TextField sx={{ input: { color: status ? 'green' : 'red' } }} name="status" variant='standard' aria-readonly={true} helperText="Status token generat" value={status ? "Token activ" : "Token inactiv"} />
			</Box>
			<Box sx={{ my: 2, width: '75%' }}>
				<Button sx={{ my: 2, width: '50%' }} type="button" variant="contained" disabled={status} onClick={() => generateToken(currentUser?.user_id)}>Generare token</Button>
			</Box>

		</Container>
	)
}

export default SettingsAnafPage;
